import React from 'react';
import {Input} from 'antd';
import {Button} from 'antd';
import './login.css';
import {sendMobile, checkMobileCode} from '../../api/dashboard';
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: '',
      code: '',
      countDown: 0,
      disableMobile: true,
      disabledLogin: true,
      sendOrNot: false,
    };
  }
  onInputMobile = (e) => {
    const mobile = e.target.value;
    this.setState({
      mobile: mobile,
      disableMobile: mobile.length<1
    })
    console.log(mobile);
    console.log(mobile.length);
  }
  onInputCode = (e) => {
    const code = e.target.value;
    this.setState({
      code: code,
      disabledLogin: code.length<6
    })
    console.log(code);
    console.log(code.length);
  }
  onSendMobile = async() => {
    const {mobile, sendOrNot} = this.state;
    console.log("onSendMobile")
    console.log(mobile)
    const respons = await sendMobile(mobile);
    console.log(respons)
    const that = this;
    if (sendOrNot) {
      //Do nothing!
      console.log("onSendMobile:Do nothing!")
    } else {
      this.setState({
        sendOrNot: true,
        countDown: 60
      },()=>{
        console.log("()=>onSendMobile")
        clearInterval(that.countDownFuncTime);
        that.countDownFuncTime = setInterval(that.countDownFunc, 1000);
      })
    }
  }
  onSendMobileAndCode = async() => {
    const {mobile, code} = this.state;
    const {setSet} = this.props;
    const respons = await checkMobileCode(mobile, code);
    console.log(respons);
    if (respons.data.code == 0) {

      var storage=window.localStorage;
      storage.setItem("mobile", mobile);
      storage.setItem("accessToken", respons.data.accessToken);
      
      //调用父亲的方法，刷新页面
      setSet({
        pageType: 2
      })
      
    }
    console.log(`mobile:${mobile}`);
    console.log(`code:${code}`);
  }
  countDownFunc = () => {
    const {countDown} = this.state;
    console.log("countDownFunc")
    console.log(countDown)
    if (countDown > 0) {
      this.setState({
        countDown: countDown - 1
      })
    } else {
      clearInterval(this.countDownFuncTime);
      this.setState({
        sendOrNot: false
      })
    }
  }
  render() {
    const {disableMobile, disabledLogin, sendOrNot, countDown} = this.state;
    return (
      <div className="loginDiv">
        <div className="title">手机验证码登录</div>
        <div className="mobileDiv">
          <Input className="text" onChange={(e)=>this.onInputMobile(e)} type="number" placeholder="输入手机号" />
          <Button disabled={disableMobile} className="sendButton" onClick={this.onSendMobile} type="primary">{sendOrNot?`已发送(${countDown})`:"发送验证码"}</Button>
        </div>
        <div className="mobileCode">
          <Input className="text" onChange={(e)=>this.onInputCode(e)} type="number" placeholder="请输入验证码"/>
        </div>
        <div className="loginBtn">
          <Button disabled={disabledLogin} className="button" onClick={this.onSendMobileAndCode} type="primary">登陆</Button>
          </div>
      </div>
    )
  }
}
  
export default Login;