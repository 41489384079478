import React from 'react';
import Login from './pages/login/login';
import logo from './logo.svg';
import { Chart } from '@antv/g2';
import './Month.css';
import { DatePicker, Space } from 'antd';
import 'antd/dist/antd.css';
import { getSummaryMonth, getStoreListMonth, getTopListMonth, checkMobileAccesstoken, getSummaryMonthWithdate, getStoreListMonthWithdate, getTopListMonthWithdate} from './api/dashboard';
import FormatFunc from './util/FormatFunc';

import { ReactComponent as ClockIcon } from './assets/img/icons/clock.svg';
import { ReactComponent as TriangleupIcon } from './assets/img/icons/triangleup.svg';
import { ReactComponent as TriangledownIcon } from './assets/img/icons/triangledown.svg';

const { RangePicker } = DatePicker;

// function App() {
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageType: 0,//0 载入页面；1 登录页面；2数据显示页面
      showDatePicker: false,
      timeNowStr: '',
      TextArray: [],
      storeList: [],
      topList: [],
      areaList: [],
      provinceList: [],
      zonuleList:[],
    };
  }

  async fetchMobileAccesstoken (mobile, accessToken) {
    const result = await checkMobileAccesstoken(mobile, accessToken);
    console.log("fetchMobileAccesstoken")
    console.log(result)
    if (result.data.code == 0) {
      this.setState({
        pageType: 2,
      })
    } else {
      this.setState({
        pageType: 1,
      })
    }
  }
  async fetchSummary (from, to) {
    const summary = await getSummaryMonth(from, to);
    const data = summary.data.data;
    this.setState({
      TextArray: [
       {
         title: '实销额',
         money: data.total.toLocaleString(),
         compare: '同比上月同时段',
         percentage: data.totalCompare,
         percentageStr: (data.totalCompare * 100).toFixed(1),
       }, {
         title: '客单数',
         money: data.orderNum.toLocaleString(),
         compare: '同比上月同时段',
         percentage: data.orderNumCompare,
         percentageStr: (data.orderNumCompare * 100).toFixed(1),
       },{
         title: '总件数',
         money: data.pieces.toLocaleString(),
         compare: '同比上月同时段',
         percentage: data.piecesCompare,
         percentageStr: (data.piecesCompare * 100).toFixed(1),

       }, {
         title: 'IPC',
         money: data.ipc,
         compare: '同比上月同时段',
         percentage: data.ipcCompare,
         percentageStr: (data.ipcCompare * 100).toFixed(1),

       }, {
         title: '客单价',
         money: Math.round(data.avt).toLocaleString(),
         compare: '同比上月同时段',
         percentage: data.avtCompare,
         percentageStr: (data.avtCompare * 100).toFixed(1),

       }, {
         title: '件单价',
         money: Math.round(data.pvt).toLocaleString(),
         compare: '同比上月同时段',
         percentage: data.pvtCompare,
         percentageStr: (data.pvtCompare * 100).toFixed(1),

       }, 
      //  {
      //    title: '总规模增长',
      //    money: data.totalImp,
      //    compare: '同比上月同时段',
      //    percentage: data.totalImpCompare,
      //    percentageStr: (data.totalImpCompare * 100).toFixed(1),

      //  }, {
      //    title: '同店增长',
      //    money: data.storeImp,
      //    compare: '同比上月同时段',
      //    percentage: data.storeImpCompare,
      //    percentageStr: (data.storeImpCompare * 100).toFixed(1),

      //  }, {
      //    title: '周环比',
      //    money: data.wtm,
      //    compare: '同比上月同时段',
      //    percentage: data.wtmCompare,
      //    percentageStr: (data.wtmCompare * 100).toFixed(1),

      //  }, 
     ]});
  }
  async fetchStoreList (from, to) {
    const storeList = await getStoreListMonth(from, to) ;

    this.setState({
      storeList: storeList.data.data.rows.map(item=>{
        return {
          topName: item[1],
          topMoney: Math.round(item[3]).toLocaleString(),
        }
      })
    })
  }
  async fetchTopList (from, to) {
    const topList = await getTopListMonth('24', from, to);
    this.setState({
      
      topList: topList.data.data.rows.map(item=>{
        return {
          topName: item[2],
          topMoney: Math.round(item[4]).toLocaleString(),
          storeNum: item[6],
          average: Math.round(item[4]/(item[6]>0?item[6]:1)).toLocaleString(),
        }
      })
    })
  }
  async fetchAreaList (from, to) {
    const topList = await getTopListMonth('23', from, to);
    this.setState({
      areaList: topList.data.data.rows.map(item=>{
        return {
          topName: item[2],
          topMoney: Math.round(item[4]).toLocaleString(),
          storeNum: item[6],
          average: Math.round(item[4]/(item[6]>0?item[6]:1)).toLocaleString(),
        }
      })
    })
  }
  async fetchProvinceList (from, to) {
    const topList = await getTopListMonth('09', from, to);
    this.setState({
      provinceList: topList.data.data.rows.map(item=>{
        return {
          topName: item[2],
          topMoney: Math.round(item[4]).toLocaleString(),
          storeNum: item[6],
          average: Math.round(item[4]/(item[6]>0?item[6]:1)).toLocaleString(),
        }
      })
    })
  }
  //任亚林复制新增小区排名函数
  async fetchZonuleList (from, to) {
    const topList = await getTopListMonth('25', from, to);
    this.setState({
      zonuleList: topList.data.data.rows.map(item=>{
        return {
          topName: item[2],
          topMoney: Math.round(item[4]).toLocaleString(),
          storeNum: item[6],
          average: Math.round(item[4]/(item[6]>0?item[6]:1)).toLocaleString(),
        }
      })
    })
  }

  async componentDidMount() {
    document.title="Re-BLUE本月实时数据"
    this.timer = setInterval(()=>{
      var timestamp = new Date().getTime()/1000;
      const timeStr = FormatFunc.timestampToDateAndTimeAndSecondHyphen(timestamp);
      this.setState({
        timeNowStr: timeStr,
      })
    }, 1000);

    //检查本地是否有登录凭证
    var storage=window.localStorage;
    var mobile = storage.getItem("mobile");
    var accessToken = storage.getItem("accessToken");
    console.log("accessToken")

    console.log(accessToken)

    if (accessToken) {
      this.fetchMobileAccesstoken(mobile, accessToken);
      this.fetchAll();
    } else {
      this.setState({
        pageType: 1,
      })
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  setSet(obj) {
    const that = this;
    this.setState(obj,()=>{that.fetchAll();});
  }
  async fetchAll() {
    this.fetchSummary('null', 'null');
    this.fetchStoreList('null', 'null');
    this.fetchTopList('null', 'null');
    this.fetchAreaList('null', 'null');
    this.fetchProvinceList('null', 'null');
    this.fetchZonuleList('null', 'null');
  }
  fetchAllWithDate = (date, dateString) =>{
    if (dateString && dateString.length > 1) {
      console.log('启动日期选择')
      const from = dateString[0];
      const to = dateString[1];
      this.fetchSummary(from, to);
      this.fetchStoreList(from, to);
      this.fetchTopList(from, to);
      this.fetchAreaList(from, to);
      this.fetchProvinceList(from, to);
      this.fetchZonuleList(from, to);
    }
  }
  showDatePicker = () => {
    console.log('spider')
    const {showDatePicker} = this.state;
    this.setState({
      showDatePicker: !showDatePicker
    })
  }

  render() {
    const {pageType} = this.state;
    switch (pageType) {
      case 0:
        return <div>载入中</div>;
      case 1:
        return <Login setSet={this.setSet.bind(this)}/>;
      case 2:
        return this.render1();
      default:
        break;
    }
  }
  render1() {
    const {showDatePicker} = this.state;
    return (
      <div className="App">
        <div className="title">全国门店数据大屏</div>
        <div className="sub-title"><ClockIcon className="clock-icon"></ClockIcon>    {this.state.timeNowStr}</div>
        {showDatePicker ? <Space direction="vertical" size={12}>
          <RangePicker showToday
          onChange={this.fetchAllWithDate}
          />
        </Space>:''}
        <div className="section">本月数据看版</div>
        <div className="dashboard">
          {this.state.TextArray.map(item=>{return (<div className="data-div">
            <div className="data-title">{item.title}</div>
            <div className="data-money">{item.money}</div>
            <div className="data-compare">
              {item.compare}
              {item.percentage > 0 ? <TriangleupIcon className="triangle-icon" /> : <TriangledownIcon className="triangle-icon" />}
              {/* <TriangleupIcon className={item.percentage > 0 ? "triangleup-icon" : "triangledown-icon"}/> */}
              <span className={item.percentage > 0 ?"data-percentup" : "data-percentdown"}>{item.percentageStr}%</span>
            </div>
          </div>)})}
        </div>
        <div className="percentage">
          <div className="percentage-layout">
            <div className="percentage-offline">100%</div>
            <div className="percentage-name">
              <div className="red-point" />
              <div className="">线下门店</div>
            </div>
          </div>
          <div className="percentage-layout">支付金额</div>
          <div className="percentage-layout">
            <div className="percentage-offline">0%</div>  
            <div className="percentage-name">线上门店
            <div  onDoubleClick={this.showDatePicker} className="red-point2"/>
            </div>
          </div>
        </div>
        {/* 门店销售排名 */}
        <div className="section">本月门店成交总金额排名</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">门店名</div>
            <div className="top-money">本月成交金额</div>
          </div>
          {this.state.storeList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}     
        </div>
        {/* 片区销售排名 */}
        {/* <div className="section">本月小片区实销金额排名Top10</div> */}
        <div className="section">本月店群1数据</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">门店名</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">本月成交金额</div>
          </div>
          {/* {this.state.topList.slice(0, 10).map((item, index)=>{return ( */}
          {this.state.topList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}     
        </div>

        {/* 店群销售排名 */}
        <div className="section">本月店群2实销金额</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">店群名</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">月总金额</div>
          </div>
          {this.state.areaList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}     
        </div>
        {/* 省份排名*/} 
        <div className="section">本月省份排名</div>  
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">省份</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">月总金额</div>
          </div>
          {this.state.provinceList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}     
        </div> 
        {/* 区域店群销售排名 */}
        <div className="section">本月小区实销金额</div>
        <div className="top">
          <div className="top-title"> 
            <div className="order"></div>
            <div className="top-name">店群名</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">月总金额</div>
          </div>
          {this.state.zonuleList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}     
          </div>

        {/* <div className="section">今日成交用户情况</div>
        <div className="user">
          <div className="blank"></div>
          <div className="user-money">900</div>
          <div className="user-percentage">女性</div>
        </div>
        <div className="age">
        </div>
        <div className="section">近30天净成交总额走势图</div> */}
      </div>
    );
  }
} 

export default App;
