import React from 'react';
import {HashRouter,Route,Switch} from 'react-router-dom';
import Daily from './Daily';
import Month from './Month';


const BasicRoute =() => (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Daily}/>
        <Route exact path="/month" component={Month}/>
      </Switch>
    </HashRouter>
);

export default BasicRoute;
