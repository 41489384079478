import axios from './api';

export const getSummary = () => {
  return axios.request({
    url: `/summary`,
    method: 'GET',
  });
};

export const getStoreList = () => {
  return axios.request({
    url: '/storelist',
    method: 'GET',
  });
};

export const getTopList = (type) => {
  return axios.request({
    url: `/toplist?type=${type}`,
    method: 'GET',
  });
};
// export const getSummaryMonth = () => {
//   return axios.request({
//     url: `/summary?duration=month`,
//     method: 'GET',
//   });
// };

// export const getStoreListMonth = () => {
//   return axios.request({
//     url: '/storelist?duration=month',
//     method: 'GET',
//   });
// };

// export const getTopListMonth = (type) => {
//   return axios.request({
//     url: `/toplist?type=${type}&duration=month`,
//     method: 'GET',
//   });
// };

export const getSummaryMonth = (from, to) => {
  return axios.request({
    url: `/summary?duration=month&from=${from}&to=${to}`,
    method: 'GET',
  });
};

export const getStoreListMonth = (from, to) => {
  return axios.request({
    url: `/storelist?duration=month&from=${from}&to=${to}`,
    method: 'GET',
  });
};

export const getTopListMonth = (type,from, to) => {
  return axios.request({
    url: `/toplist?type=${type}&duration=month&from=${from}&to=${to}`,
    method: 'GET',
  });
};

export const sendMobile = (mobile) => {
  return axios.request({
    url: `/sendMobile?mobile=${mobile}`,
    method: 'GET',
  });
};

export const checkMobileCode = (mobile, code) => {
  return axios.request({
    url: `/checkMobileCode?mobile=${mobile}&code=${code}`,
    method: 'GET',
  });
};

export const checkMobileAccesstoken = (mobile, accessToken) => {
  return axios.request({
    url: `/checkMobileAccesstoken?mobile=${mobile}&accessToken=${accessToken}`,
    method: 'GET',
  });
};
